(function() {
    'use strict';

    angular
        .module('consinfApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('visione-consenso', {
            parent: 'entity',
            url: '/visione-consenso',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'consinfApp.visioneConsenso.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/visione-consenso/visione-consensos.html',
                    controller: 'VisioneConsensoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('visioneConsenso');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('visione-consenso-detail', {
            parent: 'visione-consenso',
            url: '/visione-consenso/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'consinfApp.visioneConsenso.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/visione-consenso/visione-consenso-detail.html',
                    controller: 'VisioneConsensoDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('visioneConsenso');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'VisioneConsenso', function($stateParams, VisioneConsenso) {
                    return VisioneConsenso.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'visione-consenso',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('visione-consenso-detail.edit', {
            parent: 'visione-consenso-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/visione-consenso/visione-consenso-dialog.html',
                    controller: 'VisioneConsensoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['VisioneConsenso', function(VisioneConsenso) {
                            return VisioneConsenso.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('visione-consenso.new', {
            parent: 'visione-consenso',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/visione-consenso/visione-consenso-dialog.html',
                    controller: 'VisioneConsensoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                paziente: null,
                                nome: null,
                                emailMedicoDiBase: null,
                                emailFamiliare: null,
                                dataVisione: null,
                                fileFirma: null,
                                fileFirmaContentType: null,
                                fileFotoCartaceo: null,
                                fileFotoCartaceoContentType: null,
                                fileVideoSelfie: null,
                                fileVideoSelfieContentType: null,
                                confermaHoCapito: null,
                                invioCedAttempts: null,
                                invioCedSuccess: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('visione-consenso', null, { reload: 'visione-consenso' });
                }, function() {
                    $state.go('visione-consenso');
                });
            }]
        })
        .state('visione-consenso.edit', {
            parent: 'visione-consenso',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/visione-consenso/visione-consenso-dialog.html',
                    controller: 'VisioneConsensoDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['VisioneConsenso', function(VisioneConsenso) {
                            return VisioneConsenso.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('visione-consenso', null, { reload: 'visione-consenso' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('visione-consenso.delete', {
            parent: 'visione-consenso',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/visione-consenso/visione-consenso-delete-dialog.html',
                    controller: 'VisioneConsensoDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['VisioneConsenso', function(VisioneConsenso) {
                            return VisioneConsenso.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('visione-consenso', null, { reload: 'visione-consenso' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
