(function() {
    'use strict';

    angular
        .module('consinfApp')
        .controller('VideoInformativoController', VideoInformativoController);

    VideoInformativoController.$inject = ['DataUtils', 'VideoInformativo'];

    function VideoInformativoController(DataUtils, VideoInformativo) {

        var vm = this;

        vm.videoInformativos = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            VideoInformativo.query(function(result) {
                vm.videoInformativos = result;
                vm.searchQuery = null;
            });
        }
    }
})();
