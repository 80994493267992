(function() {
    'use strict';

    angular
        .module('consinfApp')
        .controller('OggettoConsensoDetailController', OggettoConsensoDetailController);

    OggettoConsensoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'OggettoConsenso', 'VisioneConsenso'];

    function OggettoConsensoDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, OggettoConsenso, VisioneConsenso) {
        var vm = this;

        vm.oggettoConsenso = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('consinfApp:oggettoConsensoUpdate', function(event, result) {
            vm.oggettoConsenso = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
