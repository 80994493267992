(function() {
    'use strict';

    angular
        .module('consinfApp')
        .controller('OggettoConsensoDeleteController',OggettoConsensoDeleteController);

    OggettoConsensoDeleteController.$inject = ['$uibModalInstance', 'entity', 'OggettoConsenso'];

    function OggettoConsensoDeleteController($uibModalInstance, entity, OggettoConsenso) {
        var vm = this;

        vm.oggettoConsenso = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            OggettoConsenso.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
