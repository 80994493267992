(function() {
    'use strict';

    angular
        .module('consinfApp')
        .controller('VisioneConsensoDetailController', VisioneConsensoDetailController);

    VisioneConsensoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'VisioneConsenso', 'MessaggioVoucher', 'OggettoConsenso', 'ConsInfUser'];

    function VisioneConsensoDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, VisioneConsenso, MessaggioVoucher, OggettoConsenso, ConsInfUser) {
        var vm = this;

        vm.visioneConsenso = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('consinfApp:visioneConsensoUpdate', function(event, result) {
            vm.visioneConsenso = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
