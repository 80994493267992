(function() {
    'use strict';

    angular
        .module('consinfApp')
        .controller('OggettoConsensoDialogController', OggettoConsensoDialogController);

    OggettoConsensoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'OggettoConsenso', 'VisioneConsenso'];

    function OggettoConsensoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, OggettoConsenso, VisioneConsenso) {
        var vm = this;

        vm.oggettoConsenso = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.visioneconsensos = VisioneConsenso.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.oggettoConsenso.id !== null) {
                OggettoConsenso.update(vm.oggettoConsenso, onSaveSuccess, onSaveError);
            } else {
                OggettoConsenso.save(vm.oggettoConsenso, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('consinfApp:oggettoConsensoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setContenuto = function ($file, oggettoConsenso) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        oggettoConsenso.contenuto = base64Data;
                        oggettoConsenso.contenutoContentType = $file.type;
                    });
                });
            }
        };

    }
})();
