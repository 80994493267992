(function() {
    'use strict';

    angular
        .module('consinfApp')
        .controller('ConsInfAccountDeleteController',ConsInfAccountDeleteController);

    ConsInfAccountDeleteController.$inject = ['$uibModalInstance', 'entity', 'ConsInfAccount'];

    function ConsInfAccountDeleteController($uibModalInstance, entity, ConsInfAccount) {
        var vm = this;

        vm.consInfAccount = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ConsInfAccount.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
