(function() {
    'use strict';

    angular
        .module('consinfApp')
        .controller('ConsInfUserDetailController', ConsInfUserDetailController);

    ConsInfUserDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ConsInfUser', 'User', 'VisioneConsenso', 'ConsInfAccount'];

    function ConsInfUserDetailController($scope, $rootScope, $stateParams, previousState, entity, ConsInfUser, User, VisioneConsenso, ConsInfAccount) {
        var vm = this;

        vm.consInfUser = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('consinfApp:consInfUserUpdate', function(event, result) {
            vm.consInfUser = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
