(function() {
    'use strict';

    angular
        .module('consinfApp')
        .controller('ConsInfAccountDetailController', ConsInfAccountDetailController);

    ConsInfAccountDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'ConsInfAccount', 'ConsInfUser'];

    function ConsInfAccountDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, ConsInfAccount, ConsInfUser) {
        var vm = this;

        vm.consInfAccount = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('consinfApp:consInfAccountUpdate', function(event, result) {
            vm.consInfAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
