(function() {
    'use strict';

    angular
        .module('consinfApp')
        .controller('MessaggioVoucherDetailController', MessaggioVoucherDetailController);

    MessaggioVoucherDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'MessaggioVoucher', 'VisioneConsenso'];

    function MessaggioVoucherDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, MessaggioVoucher, VisioneConsenso) {
        var vm = this;

        vm.messaggioVoucher = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('consinfApp:messaggioVoucherUpdate', function(event, result) {
            vm.messaggioVoucher = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
