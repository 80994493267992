(function() {
    'use strict';

    angular
        .module('consinfApp')
        .controller('VisioneConsensoDialogController', VisioneConsensoDialogController);

    VisioneConsensoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'VisioneConsenso', 'MessaggioVoucher', 'OggettoConsenso', 'ConsInfUser'];

    function VisioneConsensoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, VisioneConsenso, MessaggioVoucher, OggettoConsenso, ConsInfUser) {
        var vm = this;

        vm.visioneConsenso = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.messaggiovouchers = MessaggioVoucher.query();
        vm.oggettoconsensos = OggettoConsenso.query();
        vm.consinfusers = ConsInfUser.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.visioneConsenso.id !== null) {
                VisioneConsenso.update(vm.visioneConsenso, onSaveSuccess, onSaveError);
            } else {
                VisioneConsenso.save(vm.visioneConsenso, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('consinfApp:visioneConsensoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataVisione = false;

        vm.setFileFirma = function ($file, visioneConsenso) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        visioneConsenso.fileFirma = base64Data;
                        visioneConsenso.fileFirmaContentType = $file.type;
                    });
                });
            }
        };

        vm.setFileFotoCartaceo = function ($file, visioneConsenso) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        visioneConsenso.fileFotoCartaceo = base64Data;
                        visioneConsenso.fileFotoCartaceoContentType = $file.type;
                    });
                });
            }
        };

        vm.setFileVideoSelfie = function ($file, visioneConsenso) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        visioneConsenso.fileVideoSelfie = base64Data;
                        visioneConsenso.fileVideoSelfieContentType = $file.type;
                    });
                });
            }
        };

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
