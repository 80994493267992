(function() {
    'use strict';
    angular
        .module('consinfApp')
        .factory('MessaggioVoucher', MessaggioVoucher);

    MessaggioVoucher.$inject = ['$resource', 'DateUtils'];

    function MessaggioVoucher ($resource, DateUtils) {
        var resourceUrl =  'api/messaggio-vouchers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.requestDate = DateUtils.convertDateTimeFromServer(data.requestDate);
                        data.sendDate = DateUtils.convertDateTimeFromServer(data.sendDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
